<script setup>
import ODropdown from 'o365.vue.components.DropDown.vue';
import { ref, computed, onMounted } from 'vue';

const props = defineProps({
    hoursIncrement: { type: [Number, String], default: 1 },
    minutesIncrement: { type: [Number, String], default: 1 },
    is24: { type: Boolean, default: true },
    hoursGridIncrement: { type: [String, Number], default: 1 },
    minutesGridIncrement: { type: [String, Number], default: 5 },
    range: { type: Boolean, default: false },
    filters: { type: Object, default: () => ({}) },
    minTime: { type: Object, default: () => ({}) },
    maxTime: { type: Object, default: () => ({}) },
    timePicker: { type: Boolean, default: false },
    hours: { type: [Number, Array], default: 0 },
    minutes: { type: [Number, Array], default: 0 },
    customProps: { type: Object, default: null }
});

const emit = defineEmits(['update:hours', 'update:minutes']);

const timepickerRef = ref(null);

// Generate array of hours
const hoursArray = computed(() => {
    const hoursIncrement = parseInt(props.hoursGridIncrement);
    const arr = [];
    for (let i = 0; i < 24; i+=hoursIncrement) {
        arr.push({ text: i < 10 ? `0${i}` : i, value: i });
    }
    return arr;
});

// Generate array of minutes
const minutesArray = computed(() => {
    const minutesIncrement = parseInt(props.minutesGridIncrement);
    const arr = [];
    for (let i = 0; i < 60; i+=minutesIncrement) {
        arr.push({ text: i < 10 ? `0${i}` : i, value: i });
    }
    return arr;
});

function increment(value, incrementValue, maxValue) {
    let byValue = parseInt(incrementValue);
    let result = value + byValue;
    if (result < 0) {
        result = maxValue+1 + result;
    } else if (result > maxValue) {
        result = result - maxValue-1;
    }
    return result;
}

const hoursDisplay = computed(() => {
    return props.hours < 10 ? `0${props.hours}` : props.hours;
});
const minutesDisplay = computed(() => {
    return props.minutes < 10 ? `0${props.minutes}` : props.minutes;
});

onMounted(() => {
    const root = timepickerRef.value?.closest('.dp__instance_calendar');
    if (!root) { return; }
    root.addEventListener('click', (e) => {
        window.requestAnimationFrame(() => {
            const closestToggle = e.target.closest('.o365-datepicker-time-input-select');
            const dropdownToggles = Array.from(timepickerRef.value?.querySelectorAll('[data-bs-toggle="dropdown"]') ?? []);
            if (dropdownToggles.length === 0 || dropdownToggles.indexOf(e.target) !== -1) { return; }
            const dropdowns = dropdownToggles.reduce((arr, toggleEl) => {
                const dropdown = window.bootstrap.Dropdown.getInstance(toggleEl);
                if (dropdown) { arr.push(dropdown); }
                return arr;
            }, []);
            dropdowns.forEach((dropdown) => {
                if (dropdown._element !== closestToggle) {
                    dropdown.hide();
                }
            });
        });
    });
});

defineExpose({hoursArray, minutesArray});
</script>

<template>
    <div class="o365-timepicker my-2 text-center position-relative" ref="timepickerRef">
        <!-- <i class="bi bi-clock-fill position-absolute" style="left: 16px; top: 50%; transform: translateY(-50%);"></i> -->

        <div class="o365-datepicker-time-input">
            <div class="o365-datepicker-time-input-col">
                <div class="button-action" role="button" @click="$emit('update:hours', increment(hours,hoursIncrement,23))">
                    <i class="bi bi-chevron-up"></i>
                </div>
      
                <div class="dropup-center dropup">
                    <div class="button-action o365-datepicker-time-input-select" data-bs-toggle="dropdown" data-bs-auto-close="true" role="button">
                        <span>{{hoursDisplay}}</span>
                    </div>
                    <div class="dropdown-menu px-3 py-2 text-center">
                        <div class="row">
                            <div v-for="h in hoursArray" :key="h.value" class="col-3 button-action" :class="{active: h.value === hours}" style="font-size: 1.2rem" role="button" @click="$emit('update:hours', h.value)">
                                {{h.text}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="button-action" role="button" @click="$emit('update:hours', increment(hours,-hoursIncrement,23))">
                    <i class="bi bi-chevron-down"></i>
                </div>
            </div>

            <div class="o365-datepicker-time-input-col mx-4" style="font-size: 2rem">
                :
            </div>

           <div class="o365-datepicker-time-input-col">
                <div class="button-action" role="button" @click="$emit('update:minutes', increment(minutes,minutesIncrement,59))">
                    <i class="bi bi-chevron-up"></i>
                </div>
      
                <div class="dropup-center dropup">
                    <div class="button-action o365-datepicker-time-input-select" data-bs-toggle="dropdown" data-bs-auto-close="true" role="button">
                        <span>{{minutesDisplay}}</span>
                    </div>
                    <div class="dropdown-menu px-3 py-2 text-center">
                        <div class="row">
                            <div v-for="m in minutesArray" :key="m.value" class="col-4 button-action" :class="{active: m.value === minutes}" style="font-size: 1.2rem" role="button" @click="$emit('update:minutes', m.value)">
                                {{m.text}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="button-action" role="button" @click="$emit('update:minutes', increment(minutes,-minutesIncrement,59))">
                    <i class="bi bi-chevron-down"></i>
                </div>
            </div>
        </div>

    </div>
</template>